import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const Dashboard: React.FC = () => {
  const endpoint = '/certificates';
  const title = 'Certificados';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },
    minimum_limit: {
      model: 'selectSimple',
      name: 'minimum_limit',
      label: 'Mínimo de Salas visitadas',

      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
        { label: '13', value: 13 },
        { label: '14', value: 14 },
        { label: '15', value: 15 },
        { label: '16', value: 16 },
        { label: '17', value: 17 },
        { label: '18', value: 18 },
        { label: '19', value: 19 },
        { label: '20', value: 20 },
        { label: '21', value: 21 },
        { label: '22', value: 22 },
        { label: '23', value: 23 },
        { label: '24', value: 24 },
        { label: '25', value: 25 },
        { label: '26', value: 26 },
        { label: '27', value: 27 },
        { label: '28', value: 28 },
        { label: '29', value: 29 },
        { label: '30', value: 30 },


      ]

    },


    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    content: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'content',
      label: 'content Icons',
      list: [
        { label: 'Texto', name: 'text' },
        { label: 'Tamanho', name: 'width' },
        { label: 'Tamanho da fonte', name: 'size' },
        { label: 'Topo', name: 'top' },
        { label: 'Esquerda', name: 'left' },
        { label: 'Alinhamento', name: 'align' },
        { label: 'Negrito', name: 'fontWeight' },
        { label: 'Elemento HTML', name: 'element' },
      ],
    },

    exam_id: {
      alias: 'exam_id',
      model: 'selectApi',
      name: 'exam_id',
      label: 'Prova',

      endpoint,
    },

    satisfaction_survey_id: {
      alias: 'satisfaction_survey_id',
      model: 'selectApi',
      name: 'satisfaction_survey_id',
      label: 'Pesquisa de Satisfação',

      endpoint,
    },


  };






  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },
    content: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'content',
      label: 'content Icons',
      list: [
        { label: 'Texto', name: 'text' },
        { label: 'Tamanho', name: 'width' },
        { label: 'Tamanho da fonte', name: 'size' },
        { label: 'Topo', name: 'top' },
        { label: 'Esquerda', name: 'left' },
        { label: 'Alinhamento', name: 'align' },
        { label: 'Negrito', name: 'fontWeight' },
        { label: 'Elemento HTML', name: 'element' },
      ],
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',

      options: [
        { label: 'Global', value: 1 },
        { label: 'Palestra', value: 2 },
        { label: 'Evento', value: 3 }
      ]

    },

    room_id: {
      alias: 'room_id',
      model: 'selectApiDirect',
      name: 'room_id',
      label: 'Palestra',
      endpoint: '/lessons',
    },
    height: {
      model: 'input',
      type: 'text',
      name: 'height',
      label: 'Altura do certificado',
    },
    days: {
      model: 'input',
      type: 'text',
      name: 'days',
      label: 'Data',
    },
    hours: {
      model: 'input',
      type: 'text',
      name: 'hours',
      label: 'Horas',
    },
  };


  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.uploadSubscribeCertificate({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
