import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { authTitle, logo } from '../../config';
import { Container, Content, Background, AnimatedDiv2 } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import { useLanguage } from '../../hooks/Language';
import api from '../../services/api';
import Loader from '../../components/Loader';

interface SignInData {
  email: string;
  name: string;
}

interface IProps {
  hash: string;
}

const SpeakerLink: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();

  const { hash } = useParams<IProps>();

  if (!hash) {
    setMessage('Hash inválida');

  }



  const handleSubmit = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const shape = {
          name: Yup.string().required(translate('Nome obrigatório')),
          email: Yup.string()
            .required(translate('E-mail obrigatório'))
            .email(translate('Preencha um e-mail válido')),

        };


        const schema = Yup.object().shape(shape);

        await schema.validate(data, { abortEarly: false });

        const { email, name } = data;


        const response = await api.post(`/events/access/${hash}`, { email, name });

        const { token, user, lesson } = response.data;

        api.defaults.headers.Authorization = `Bearer ${token}`;

        localStorage.setItem(`@${authTitle}:token`, token);
        localStorage.setItem(`@${authTitle}:user`, JSON.stringify(user));

        setTimeout(() => {
          setLoading(false);
          window.location.href = `/dashboard/${lesson}`;
        }, 2000)

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);
          return formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: translate('Erro'),
          description: err.response.data.message,
        });
        setLoading(false);
      }
    },
    [],
  );



  if (message) {
    return (<Container>
      <Content>
        <AnimatedDiv2>
          <h2>message</h2>
        </AnimatedDiv2>
      </Content>
    </Container>)
  }

  return (
    <Container>
      {loading && <Loader message="Carregando..." />}
      <Content>
        <AnimatedDiv2>


          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>{translate('Acessar')}</h2>
            <Input icon={FiUser} name="name" placeholder={translate('Nome')} />
            <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />

            <Button type="submit" style={{ width: "100%" }}>{translate('Entrar')}</Button>


          </Form>
        </AnimatedDiv2>
      </Content>
    </Container>
  );
};
export default SpeakerLink;
