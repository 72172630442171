import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import { dadosOrganizacao, logo } from '../../config';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';

import { Container, ContainerWhite } from './styles';

import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Termos: React.FC = () => {
  return (
    <>
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{ textAlign: 'justify' }}>
          <h2> AVISO DE PRIVACIDADE</h2>
          <br />
          <br />
          <strong>Identificação</strong>
          <br />          <br />
          O <strong>SISTEMA OCERGS</strong> composto pelo <strong>SERVIÇO NACIONAL DE APRENDIZAGEM DO COOPERATIVISMO DO ESTADO DO RIO GRANDE DO SUL – SESCOOP/RS</strong>, inscrito no CNPJ sob nº 10.510.590/0001-56 e pelo <strong>SINDICATO E ORGANIZAÇÃO DAS COOPERATIVAS DO ESTADO DO RIO GRANDE DO SUL – OCERGS</strong>, inscrito no CNPJ sob nº 92.685.460/0001-19, estabelecido na Rua Félix da Cunha nº 12, bairro Floresta, município de Porto Alegre/RS, atua como <strong>controlador</strong> dos seus dados pessoais para as atividades descritas neste aviso.<br />
          Em atendimento à Lei Geral de Proteção de Dados Pessoais (LGPD), nós fornecemos a você neste documento, as informações necessárias sobre os seus direitos e nossas obrigações. Ainda, explicamos como, para quê e quando seus dados pessoais são tratados.
          <br />
          <br />
          <strong>Dados pessoais utilizados e para quais finalidades</strong>
          <br />
          Trataremos seus dados pessoais conforme especificado no quadro abaixo:
          <br />
          <br />
          <table className='table'>
            <tbody>
              <tr><td colSpan={2}><strong>Fórum de RH</strong></td></tr>
              <tr><td  >Dados Pessoais Utilizados</td><td>Nome, e-mail, CPF, telefone e cooperativa (vínculo profissional)</td></tr>
              <tr><td  >Finalidades</td><td>    a) Realizar a sua inscrição no Fórum de RH promovido por nós;<br />
                b) Manter você informado sobre o Fórum de RH; </td></tr>

              <tr>
                <td>Fundamentos Legais</td>
                <td>Consentimento</td>
              </tr>
              <tr><td colSpan={2}><strong>Captação de novos leads</strong></td></tr>
              <tr>
                <td>Dados Pessoais Utilizados</td>
                <td>Nome, e-mail e cooperativa (vínculo profissional)</td>
              </tr>
              <tr>
                <td>Finalidade</td>
                <td>Possibilitar o envio de informações institucionais sobre o cooperativismo.</td>
              </tr>
              <tr>
                <td>Fundamento Legal</td>
                <td>Legítimo Interesse</td>
              </tr>

            </tbody>
          </table>

          <br />
          <br />
          <strong>Forma e duração da utilização dos seus dados</strong>
          <br />          <br />
          O tratamento dos dados pessoais ocorrerá através do preenchimento do formulário de inscrição disponibilizado no site do evento e importação dos seus dados para a nossa base interna de mailing.
          Os dados pessoais obtidos através do formulário de inscrição serão mantidos por nós:
          <br /><br />
          <br /> • Evento: enquanto forem necessários para o atendimento das finalidades acima determinadas ou até ocorrer a revogação do consentimento concedido, o que ocorrer primeiro;
          • Newsletter: enquanto você não se opor ao tratamento dos seus dados para fins de e-mail marketing.
          <br /><br />
          Lembramos que você pode a qualquer momento solicitar a revogação/eliminação dos dados obtidos com o seu consentimento, ocasião em que efetuaremos o descarte deles, exceto em relação aos atos já praticados sob amparo do consentimento anteriormente manifestado ou se estivermos obrigados por decisão judicial ou legal a manter estas informações, sendo que nestas duas últimas hipóteses lhe informaremos prontamente.
          <br /><br />
          Além disso, você pode a qualquer momento se descadastrar de nosso e-mail marketing através do link disponível no rodapé de todas as nossas comunicações.
          <br /><br />
          <strong>Sobre o compartilhamento dos dados pessoais</strong>
          <br /><br />
          Os dados pessoais serão tratados pelos colaboradores envolvidos na realização das atividades descritas neste aviso e serão compartilhados com a Encontro Digital para possibilitar a sua inscrição no evento, com o Instituto Caldeira para possibilitar o seu ingresso no local do evento e com a Dinamize para possibilitar o envio de e-mail marketing com informações pertinentes ao evento ou ao cooperativismo em geral.
          <br /><br />
          No entanto, reforçamos que estes compartilhamentos observarão os princípios estabelecidos na Lei Geral de Proteção de Dados Pessoais (LGPD), bem como os operadores (parceiros) envolvidos estão engajados na adoção de rígidos padrões de segurança e de adequação com a legislação vigente, no intuito de garantir a segurança dos seus dados pessoais e da sua privacidade.
          <br /><br />
          <strong>Direitos do titular</strong>     <br /><br />
          A Lei Geral de Proteção de Dados Pessoais (LGPD) garante a você uma série de direitos sobre seus dados pessoais e você pode exercê-los através dos e-mails privacidade@ocergs.coop.br ou privacidade@sescooprs.coop.br.
          De acordo com a lei, você tem direito a:<br /><br />
          <br />• Confirmar a existência de tratamento de dados pessoais;
          <br />  • Acessar os seus dados pessoais, caso exista tratamento;
          <br />   • Corrigir dados pessoais incompletos, inexatos ou desatualizados;
          <br />   • Anonimizar (impossibilitar a sua identificação através do dado), bloquear ou eliminar dados desnecessários, excessivos ou tratados em desconformidade com a lei;
          <br />   • Eliminar os dados pessoais tratados por ocasião da(s) atividade(s) descrita(s);
          <br />   • Obter informações sobre o compartilhamento de seus dados com terceiros;
          <br />   • Se opor aos tratamentos realizados e indicados neste documento;
          <br />   • Revogar o consentimento previamente concedido para tratamento de seus dados; e/ou
          <br />    • Nos casos em que não tratamos adequadamente suas solicitações, registrar reclamação perante a Autoridade Nacional de Proteção de Dados – ANPD.
          <br /> <br />
          Se recebermos uma solicitação sua para exercer qualquer um dos direitos acima, podemos pedir que você confirme sua identidade antes de atendermos a solicitação, com o objetivo de garantir que seus dados estão protegidos e mantidos em segurança.
          <br /> <br />
          <strong>Como nos contatar</strong>
          <br /> <br />
          Já informamos nos itens acima, mas não custa repetir: você pode nos contatar a qualquer momento, para solicitar informações, exercer seus direitos ou recomendar sugestões. Basta enviar e-mail para privacidade@ocergs.coop.br ou privacidade@sescooprs.coop.br.
        </div>
      </ContainerWhite>
    </>
  );
};
export default Termos;
