import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, Wrapper, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';
import { loadFromJson } from '../../utils/loadFromJson';
import { hash } from '../../config';


interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id: number;

  id: number;
  category_response?: Record<any, string>;
  social?: Record<any, string>;

}

interface ISpeakers {
  title: string;
  position: string;
  order_type: string;
  status_id?: string;
  speakers: Array<ISpeakerData>;
}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
const Speakers: React.FC = () => {
  const { configuration } = useConfiguration();
  const [speakersData, setSpeakerData] = useState<Array<ISpeakers>>([]);
  const { projectHash = configuration?.url, eventHash } = useParams<IParamsURL>();




  const [theme, setTheme] = useState({
    titleColor: configuration?.current_event_id_response?.theme?.speaker_title_color || 'rgb(0,14,64)',
    textColor: configuration?.current_event_id_response?.theme?.speaker_text_color || 'rgb(0,14,64)',
    background: configuration?.current_event_id_response?.theme?.speaker_background || '#fff',

  })


  useEffect(() => {
    setTheme({
      titleColor: configuration?.current_event_id_response?.theme?.speaker_title_color || '#fff',
      textColor: configuration?.current_event_id_response?.theme?.speaker_text_color || '#fff',
      background: configuration?.current_event_id_response?.theme?.speaker_background || '#333',
    })
  }, [configuration])


  const orderList = (item) => {

    const valid = {
      asc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
          })
        }

        return speakers;

      },
      desc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            return a.name < b.name ? 1 : a.name > b.name ? -1 : 0
          })
        }

        return speakers;

      },
    }


    if (valid[item?.order_type]) {
      item.speakers = valid[item?.order_type](item?.speakers);
    }

    return item.speakers || [];

  }

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'order_id', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      const speakers: Array<ISpeakers> = [
        {
          title: 'Palestrantes',
          speakers: [],
          position: '1',
          order_type: 'asc'
        },

      ];


      let data: Array<ISpeakers> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: projectHash, table: 'SpeakerPosition' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(!eventHash ? `/speakers-list/${projectHash}?${string}&limitItems=50` : `/speakers-list/${projectHash}/${eventHash}?${string}&limitItems=50`);

        data = response.data;
      }

      if (data) {

        let newSpeakers: Array<ISpeakers> = [];

        data.map((a: ISpeakers) => {

          a.speakers = orderList(a);

          newSpeakers.push(a);



        })

        newSpeakers.sort((a, b) => {
          return a.position > b.position ? 1 : a.position < b.position ? -1 : 0
        })



        setSpeakerData(newSpeakers);
      }
    };
    load();
  }, []);

  return speakersData.length > 0 ? <Wrapper style={{ background: theme?.background, maxWidth: configuration?.theme_data?.speakers_max_size ? configuration?.theme_data?.speakers_max_size : '1000px' }} className='speaker-container'><Container className={` ${configuration?.theme_data?.speakers_className && configuration?.theme_data?.speakers_className}`}> {speakersData.map(s => s.status_id === 'Sim' ? <SpeakersContainer theme={theme} speakers={s.speakers} title={s.title} /> : <></>)}</Container></Wrapper> : <></>;
};

export default Speakers;
