import React from 'react';
import { FaChalkboardTeacher, FaFileExcel } from 'react-icons/fa';
import CopyToClipboard from '../../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../../hooks/Auth';
import downloadit, { downloaditPost } from '../../../../../../utils/download';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import api from '../../../../../../services/api';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ExportList({ lineData }: ICreateModule): JSX.Element {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();

  const download = async () => {
    addLoading({ title: 'Carregando', key: 'loading' })

    const sales = await api.get('/sales', {
      params: {
        limitItems: 10000,
        where: { project_id: lineData?.project_id },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          client_id: { ref: 'client_id', label: 'Cliente', search: true },

        }

      }
    });
    const students = await api.get('/students', {
      params: {
        limitItems: 10000,
        where: { project_id: lineData?.project_id },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          name: { ref: 'name', label: 'Nome', search: true },
          company: { ref: 'company', label: 'Empresa', search: true },
          email: { ref: 'email', label: 'E-mail', search: true },
        }
      }
    });
    const activities = await api.get('/course-products-subscriptions', { params: { limitItems: 10000, where: { project_id: lineData?.project_id }, } });


    const list: Array<Array<string>> = [['Participante do evento', 'Inscrito no Jantar?', 'Acompanhante', 'Empresa', 'E-mail', 'Ramo da Cooperativa']];

    sales?.data?.rows?.map(sale => {

      const student = students?.data?.rows?.find(s => s?._id?.toString() === sale?.client_id);
      const activity = activities?.data?.rows?.find(s => s?.client_id?.toString() === sale?.client_id && s.companion_id !== sale?.client_id);
      const activityCompanion = activities?.data?.rows?.find(s => s?.client_id?.toString() === sale?.client_id && s.companion_id === sale?.client_id);

      list.push([
        student?.name || 'Não identificado',
        activity ? 'Sim' : 'Não',
        activityCompanion?.name ? activityCompanion?.name : '',
        student?.company || '',
        student?.email || '',
        student?.extra_1


      ])


    })

    list.unshift(['', '', '', '', '', '']);

    list.unshift([`Total de inscritos no jantar (incluindo acompanhantes): ${activities?.data?.rows?.length}`, '', '', '', '', '']);

    list.unshift([`Total de inscritos no evento: ${sales?.data?.rows?.length}`, '', '', '', '', '']);

    downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: list, type: 'xlsx' });
    removeLoading('loading');

  }

  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button title="Exportar Lista com Jantar" type='button' onClick={() => download()} className='lineIcon'><FaFileExcel /></button> : <></>}
    </>;
  }



  return moduleList();
}
