
import React, { useEffect, useState } from 'react';
import { FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { urlWebsite } from '../../../../config';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
import {

    Capa, Container,



} from '../Themes/SinglePageTheme/styles';

const HomeApplication = () => {
    const { configuration, projectHash } = useConfiguration();
    const { translate } = useLanguage();

    const [theme, setTheme] = useState({
        titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
        textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
        buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
        buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
    })


    useEffect(() => {
        setTheme({
            titleColor: configuration?.current_event_id_response?.theme?.title_color || '#fff',
            textColor: configuration?.current_event_id_response?.theme?.text_color || '#fff',
            buttonColor: configuration?.current_event_id_response?.theme?.button_color || 'rgb(78, 27, 129)',
            buttonBackground: configuration?.current_event_id_response?.theme?.button_background || '#3FB8EB',
        })
    }, [configuration])


    return <Container>
        <Capa>
            <div className='header'>
                {configuration?.current_event_id_response?.image ? <img src={configuration?.current_event_id_response?.image ? `${urlWebsite}/${configuration?.current_event_id_response?.image}` : '/assets/logo.png'} /> : <></>}
                <div>
                    { /* <h2 style={{ color: theme?.titleColor }}><strong style={{ color: theme?.titleColor }} >{configuration?.current_event_id_response?.title}</strong></h2> */}

                    {configuration?.description ? <p className='paragraph' dangerouslySetInnerHTML={{ __html: configuration?.description }} style={{ fontSize: '16px', color: theme?.textColor, marginTop: '15px' }} /> : <></>}

                    <aside className='subtitle' style={{ color: theme?.textColor }}>{configuration?.current_event_id_response?.date_text}</aside>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '15px', flexWrap: 'wrap' }}>
                        {configuration?.active_pages && configuration?.active_pages?.map(button => {
                            return button?.button !== 'yes' ? <></> : button?.type === 'external' ? <a href={button.url} target="_BLANK" ><button className='greenButton2 shadow' style={{ background: theme?.buttonBackground, color: theme?.buttonColor }}>{button?.title}</button></a> : <Link to={`/app/${projectHash}/${button.url}`} ><button className='greenButton2 shadow' style={{ background: theme?.buttonBackground, color: theme?.buttonColor }}>{button?.title}</button></Link>
                        })}


                    </div>

                </div>

            </div>





        </Capa>
        {configuration?.current_event_id_response?.local ? <div className='localDiv'>
            <FaMapMarker size={30} color={"#fff"} />
            <div>
                <p className='mapiconP'> {translate(configuration?.current_event_id_response?.local)} {configuration?.current_event_id_response?.local_description ? <> <br />
                    {translate(configuration?.current_event_id_response?.local_description)}</> : <></>}  </p>


            </div>

        </div> : <></>}
        <Capa>





        </Capa>
    </Container>

}

export default HomeApplication;