import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft, FiX } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp, FaCheck, FaExclamation, FaPlus } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { defaultProjectHash, hash } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';
import { useModal } from '../../hooks/Modal';
import { useLoading } from '../../hooks/LoadingHook';
import Input from '../DashboardPages/core/components/Forms/Input';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
}
const CourseProductsSubscriptionModule: React.FC<IProps> = ({ filterCategory = '', removeCategory = '' }) => {
  const [globalActivitiesList, setGlobalActivitiesList] = useState<Array<Record<string, any>>>([]);
  const { addLoading, removeLoading } = useLoading();
  const [currentDay, setCurrentDay] = useState(0);
  const { addModal, removeModal } = useModal();
  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash } = useParams<Record<string, any>>();
  const theme = configuration?.current_event_id_response?.theme;
  const [companions, setCompanions] = useState<Array<Record<string, any>>>([]);

  const load = async () => {

    addLoading({ title: 'Carregando', key: 'loading' });

    try {


      const listActivities = await api.get(`/list-activities/${projectHash}`);
      const listMyActivities = await api.get(`/list-my-activities/${projectHash}`);
      const listMyActivitiesCompanion = await api.get(`/list-my-activities-companion/${projectHash}`);

      const data = listActivities.data.rows;

      const activitiesList: Array<Record<string, any>> = [];
      const myActivitiesList: Array<Record<string, any>> = [];

      listMyActivities?.data?.rows?.map(myActivity => {

        const index = listActivities?.data?.rows?.findIndex(activity => activity?._id?.toString() === myActivity?._id?.toString());

        if (index < 0) {
          myActivitiesList.push(myActivity)
        }
        else {
          myActivitiesList.push(listActivities?.data?.rows[index])
        }

      })


      if (data) {
        data.map((elem) => {
          const textDate = simpleDate(elem.date);

          const index = activitiesList?.findIndex(activityDates => activityDates?.simpleDate === textDate);

          /* check if the activity is already selected */
          const indexActivity = myActivitiesList?.findIndex(activity => activity?.course_product_id?.toString() === elem?._id?.toString());


          let alreadyHaveGroup = false;

          /* have any activity from the same group, but not the same activity */

          elem?.group?.map(groupValue => {


            const indexActivityGroup = myActivitiesList?.findIndex(activityItems => activityItems?.group?.findIndex(g => g === groupValue) >= 0);

            if (indexActivityGroup >= 0) {
              alreadyHaveGroup = true;
            }

          })


          if (indexActivity >= 0) {
            elem.currentStatus = 'selected';
          }
          else if (alreadyHaveGroup) {
            elem.currentStatus = 'blocked';
          }
          else if (elem?.status === 'no') {
            elem.currentStatus = 'ended';
          }
          else if (elem?.quantity <= elem?.quantity_used) {
            elem.currentStatus = 'full';
          }
          else {
            elem.currentStatus = 'available';
          }


          if (index < 0) {
            activitiesList.push({
              date: elem.date,
              formatted_date: dateLanguage(elem.date),
              simpleDate: textDate,
              activities: [elem]
            })
          }
          else {
            activitiesList[index].activities.push(elem)
          }



        });


        activitiesList.map((item, index) => {
          activitiesList[index].activities.sort((a, b) => {
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
          });
        });

        activitiesList.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;

        });

        if (listMyActivitiesCompanion?.data?.rows) {

          const rows: Array<Record<string, any>> = [];

          listMyActivitiesCompanion?.data?.rows?.map(companionValue => {

            const content = listActivities?.data?.rows?.find(activity => activity?._id?.toString() === companionValue?.course_product_id_response?._id?.toString());


            const item = { ...companionValue };
            item.currentStatus = 'selected';
            item.title = content?.title;
            item.description = content?.description;

            rows.push(item);

          })

          setCompanions(rows);
        }
        setGlobalActivitiesList(activitiesList);
        removeLoading('loading');
      }
    }
    catch (err) {
      removeLoading('loading');
    }
  };

  useEffect(() => {


    load();
  }, [user]);

  const confirmActivity = async (id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.post(`/confirm-activity-participation/${id}`);

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Adicionado com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const declineActivity = async (id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.put(`/decline-activity-participation/${id}`);

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Desistência realizada com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const declineActivityCompanion = async (id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.put(`/decline-activity-companion/${id}`);

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Acompanhante removido com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const askModal = (activityInfo, type) => {

    if (type === 'confirm') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Confirmar participação nesta atividade?</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>{activityInfo?.title}</p>
          <button className='buttonBigGreen' onClick={() => confirmActivity(activityInfo?._id?.toString())}>Confirmar</button>
        </div>
      })
    }
    else if (type === 'declineCompanion') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Você deseja remover este acompanhante?</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Atividade: {activityInfo?.title}</p>
          <p style={{ color: '#333', fontSize: '16px' }}>Convidado: {activityInfo?.name}</p>
          <button className='buttonBigGreen' style={{ background: 'rgb(150,0,0)' }} onClick={() => declineActivityCompanion(activityInfo?.course_product_id?.toString())}>Remover</button>
        </div>
      })
    }
    else if (type === 'decline') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Você deseja desistir desta atividade?</h2>
          {activityInfo?.allow_companion === 'yes' ? <p style={{ color: '#333', fontSize: '16px' }}>Seus convidados também serão removidos</p> : <></>}

          <p style={{ color: '#333', fontSize: '16px' }}>Atividade: {activityInfo?.title}</p>
          <button className='buttonBigGreen' style={{ background: 'rgb(150,0,0)' }} onClick={() => declineActivity(activityInfo?._id?.toString())}>Desistir</button>
        </div>
      })
    }
    else if (type === 'blocked') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Atividade Bloqueada</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Você só pode se inscrever em uma atividade por horário / data</p>
        </div>
      })
    }
    else if (type === 'ended') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Vagas esgotadas</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Selecione outra atividade</p>
        </div>
      })
    }
  }

  const checkStatus = (line, companion = false) => {

    if (companion) {
      return <button className='buttonBigGreenIcon negateOnHover' onClick={() => { askModal(line, 'declineCompanion') }} style={{ background: 'rgb(0,150,50)' }} title="Selecionado"><FaCheck className='confirm' /> <FiX className='decline' /> </button>;
    }
    else if (line?.currentStatus === 'selected') {
      return <button className='buttonBigGreenIcon negateOnHover' onClick={() => { askModal(line, 'decline') }} style={{ background: 'rgb(0,150,50)' }} title="Selecionado"><FaCheck className='confirm' /> <FiX className='decline' /> </button>;
    }
    else if (line?.currentStatus === 'blocked') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'blocked') }} style={{ background: 'rgb(150,150,150)' }} title="Bloqueado"><FaExclamation /> </button>;

    }
    else if (line?.currentStatus === 'ended') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'ended') }} style={{ background: 'rgb(150,0,0)' }} title="Encerrado"><FiX /> </button>;

    }
    else if (line?.currentStatus === 'full') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'ended') }} style={{ background: 'rgb(150,0,0)' }} title="Esgotado"> <FiX /></button>;

    }
    else {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'confirm') }} style={{ background: 'rgb(0,50,150)' }} title="Adicionar"><FaPlus /> </button>;

    }




  }

  const addCompanion = async (id, data) => {

    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.post(`/confirm-activity-companion/${id}`, { name: data?.name, email: data?.email, document_number: data?.document_number, company: data?.company, position: data?.position });

      await load();
      removeModal('companionConfirm');
      removeLoading('loading');
      addToast({ title: 'Adicionado com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }


  }

  const openCompanionModal = (id) => {

    addModal({
      theme: 'whiteModalMedium',
      key: 'companionConfirm',
      title: '', content: <>

        <Form onSubmit={(data) => addCompanion(id, data)}>
          <h2 style={{ color: '#333', marginBottom: '15px', width: '100%', textAlign: 'center' }}>Dados do acompanhante</h2>
          <Input placeholder='Nome do(a) acompanhante' name="name" type="text" />
          <button style={{ background: theme?.button_background, color: theme?.button_color, fontSize: '14px', margin: '0px auto', marginTop: '15px' }} className='greenButton2'>Confirmar</button>
        </Form>
      </>
    })

  }

  const getCompanionsCount = (line) => {
    return companions?.filter(c => c.course_product_id === line?._id)?.length;
  }

  const line = program => {

    return (
      <tr style={{ cursor: 'pointer' }} onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td className="hourDiv" >
          {checkStatus(program)}

        </td>  <td style={{ width: '100%' }}>

          <span style={{ width: '100%', display: 'block' }}>
            <strong style={{ color: '#1d436f', textAlign: 'left', width: '100%', display: 'block' }}>
              {' '}

              {program.title}
              {' '}


            </strong>

            {program.description && <div className={"hover"}>
              <div style={{ width: '100%' }}>


                <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

              </div>


            </div>}
          </span>

          {program.allow_companion === 'yes' && program?.currentStatus === 'selected' && getCompanionsCount(program) < program?.companion_quantity ? <div style={{ display: 'flex', marginTop: '10px', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
            <button onClick={() => openCompanionModal(program._id?.toString())} type='button' style={{ background: theme?.button_background, color: theme?.button_color, fontSize: '14px' }} className='greenButton2'>Adicionar acompanhante</button>
            <p style={{ fontSize: '12px', textAlign: 'left' }}>Límite de {program.companion_quantity} acompanhante(s)</p>
          </div> : <></>}

          {program?.companion_quantity > 0 && getCompanionsCount(program) === program?.companion_quantity ? <p style={{ fontSize: '12px', textAlign: 'left', display: 'block', color: 'rgb(29, 67, 111)', marginTop: '20px', fontWeight: 'bold' }}>{program?.companion_quantity === 1 ? 'Obrigado por confirmar seu acompanhante.' : 'Obrigado por confirmar seus acompanhantes'}</p> : <></>}
        </td>

      </tr>
    );
  };

  const lineCompanion = program => {
    return (
      <tr style={{ cursor: 'pointer' }} onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td className="hourDiv" >
          {checkStatus(program, true)}

        </td>  <td style={{ width: '100%' }}>

          <span style={{ width: '100%', display: 'block' }}>
            <strong style={{ color: '#1d436f', width: '100%', textAlign: 'left', display: 'block' }}>
              {' '}

              {program.title}
              {' '}


            </strong>

            {program.name && <div className={"hover"}>
              <div style={{ width: '100%' }}>
                <div style={{ color: '#333', fontSize: '16px', marginTop: '10px', textAlign: 'left' }}>
                  <div style={{ color: '#777', fontSize: '12px', textAlign: 'left' }}>Acompanhante:</div>
                  <div style={{ color: '#333', fontSize: '12px', textAlign: 'left' }}>{program.name}</div>

                </div>



              </div>


            </div>}
          </span>


        </td>

      </tr>
    );
  };

  return (
    <Container style={{ background: '#fff' }}>
      <h1 style={{ color: '#1d436f', fontSize: '18px' }}>{translate('Atividades adicionais')} </h1>





      {globalActivitiesList.map(program => {
        return (program?.activities?.length > 0 ? <div>
          <div className="programDayHeader" >


            {program.formatted_date && <h2 style={{ background: '#1d436f', color: '#fff', marginTop: '30px', padding: '10px', marginLeft: '7px' }}>{translate(program.formatted_date)}</h2>}

          </div>
          <table>

            <tbody>{program.activities.map(items => line(items))}</tbody>
          </table>

        </div> : <></>)
      })}

      {companions?.length > 0 ? <div>

        <h2 style={{ color: '#333', marginTop: '15px' }}>Acompanhantes</h2>
        <table>

          <tbody>{companions?.map(items => lineCompanion(items))}</tbody>
        </table>

      </div>
        : <></>}

    </Container>
  );
};
export default CourseProductsSubscriptionModule;
