import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const CertificatesUsers: React.FC = () => {
  const endpoint = '/certificates-user';
  const title = 'Certificados Gerados';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    certificate_id: {
      ref: 'certificate_id',
      column: 'certificate_id_response',
      includeColumn: 'title',
      label: 'Produto',
    },
    user_id: {
      ref: 'user_id',
      column: 'user_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    hash: {
      model: 'input',
      type: 'text',
      name: 'hash',
      label: 'Hash',
    },
    user_id: {
      alias: 'user_id',
      model: 'selectApi',
      name: 'user_id',
      label: 'Cliente',
      endpoint,
    },
    certificate_id: {
      alias: 'certificate_id',
      model: 'selectApi',
      name: 'certificate_id',
      label: 'Certificado',

      endpoint,
    },

  };






  const formSchemaUpdate = {
    ...formSchema,

  };


  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.showCertificate({
          lineData,

        }),

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CertificatesUsers;
